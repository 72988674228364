import React, { useMemo } from 'react';
import { css, keyframes } from '@emotion/css';

interface FRWProp {
  data: {
    name: string;
    className: string;
  };
}

const RW: React.FC<FRWProp> = ({ data }) => {

const spin = keyframes`
from { transform: rotate(0deg); }
    to   { transform: rotate(360deg); }
`;

// Memoize the spinning div to prevent unnecessary re-renders
const spinningDiv = useMemo(() => (
  <>
    <div
      className={css`
        width: 145px;
        height: 145px;
        animation: ${spin} 5s linear infinite;
        border: 6px solid rgba(255, 0, 0, 0.7);
        border-top-color: #3498db;
        border-radius: 50%;
        position: absolute;
        top: 5%;
        left: 25%;
      `}
    />
    <div
      className={css`
        width: 115px;
        height: 115px;
        animation: ${spin} 5s linear infinite;
        border: 6px solid rgba(255, 0, 0, 0.7);
        border-top-color: #3498db;
        border-radius: 50%;
        position: absolute;
        top: 14%;
        left: 30%;
      `}
    />
  </>
), [spin]); // `spin` dependency to ensure this only re-renders if `spin` chan

  return (
    <div className={`${data.className}`}>

      <div className={css`
      position: relative;
      background-color: rgb(38,38,38);
      `}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width="161px"
          height="161px"
          viewBox="-0.5 -0.5 161 161"
        >
          <defs />
          <g>
            <rect
              x="3"
              y="3"
              width="155"
              height="155"
              rx="24"
              ry="24"
              fill="rgb(38,38,38)"
              stroke="#FFFFFF"
              strokeWidth="5"
              pointerEvents="all"
            />
            <ellipse
              cx="80"
              cy="80"
              rx="70"
              ry="70"
              fill="rgb(38,38,38)"
              stroke="#FFFFFF"
              strokeWidth="5"
              pointerEvents="all"
            />
            <ellipse
              cx="80"
              cy="80"
              rx="55"
              ry="55"
              fill="rgb(38,38,38)"
              stroke="#FFFFFF"
              strokeWidth="5"
              pointerEvents="all"
            />
            <ellipse
              cx="80"
              cy="80"
              rx="10"
              ry="10"
              fill="blue"
              stroke="#FFFFFF"
              strokeWidth="3"
              pointerEvents="all"
            />
          </g>
        </svg>

        {spinningDiv}
      </div>

    </div>
  );
};

export default RW;

